import { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
import { baStore } from "@/store";
import { MAINTENANCE_RESPONSE } from "@/utils/constants";

export default function (context: any): void {
  context.$axios.defaults.baseURL = process.env.API_URL;

  /**
   * TODO: 2023/11/21
   * 共通のエラー処理を使用するAPIの一覧です。
   * 一時的に作成しているリストですので、全ての呼び出し側APIの修正が終わったら削除してください。
   *
   * ・[key]はAPIのパスをセットしてください。可変項目は{param}のように中括弧で囲ってください。BEのapi.phpと同じにしておけばOKです。
   *    ex: /api/v1/detail/{company_unique_code}/{join_date}
   * ・[value]は対象のHTTPメソッドを小文字でセットしてください。
   * ・[key]のパスが一致する可変項目がある場合は、以下のように可変項目が存在する方を下に記載してください。{param}は何でもOKなためです。
   *
   *   1. /api/v1/user_service/planinfo"
   *   2. /api/v1/user_service/{user_unique_code}"
   */
  const errorHandlingRoutes: Record<string, string[]> = {
    "/api/v1/admin/companies": ["get"],
    "/api/v1/user_service": ["get"],
    "/api/v1/user_service/permissions": ["get"],
    "/api/v1/user_service/business/{user_unique_code}": ["get"],
    "/api/v1/user_service/subscriptions": ["post", "put"],
    "/api/v1/user_service/unsubscriptions": ["put", "delete"],
    "/api/v1/user_service/planinfo": ["get"],
    "/api/v1/user_service/{user_unique_code}": ["get", "put", "delete"],
    "/api/v1/master/service/terms/{bank_code}": ["get"],
    "/api/v1/receive/user-general-settings/aiocr-enabled": ["get", "post"]
  };

  /**
   * Sentryへ通知しないエラーか判定します
   * @param e
   * @returns
   */
  const shouldIgnoreEvent = (e: AxiosError) => {
    if (e.message.includes("Network Error") && e.config?.url?.endsWith("oidc/ba-payment/token")) return true;
    if (e.message.includes("Both token and refresh token have expired. Your request was aborted.")) return true;

    return false;
  };

  /**
   * Request時のイベントハンドラ
   */
  context.$axios.onRequest((config: AxiosRequestConfig) => {
    // ロギング用の共通データをヘッダーにセット
    const screenName =
      document.title.split("｜")[0].trimEnd() || (context.route && context.route.fullPath)
        ? context.route.fullPath
        : "-";

    // TODO: 「/api/v1/user_service/me」と「${xbaUrl}/api/v1/user/me」が廃止になったら削除すること
    // noLoadingが設定されていた場合はcontext.store._vm.$nuxt.$loading.start()しない
    const noLoading = config.headers.noLoading ?? false;
    delete config.headers.noLoading;

    config.headers = {
      ...config.headers,
      ...{
        "X-Amzn-Trace-Id": "Root=" + uuidv4(),
        "X-Ba-Bank-Name": encodeURI(baStore.user.ba_user_bank_name),
        "X-Ba-Screen-Name": encodeURI(screenName),
        "X-Ba-Bank-Code": baStore.user.ba_user_bank_code
      }
    };

    // 非同期処理中のインジケーターを表示する
    context.store._vm.$nextTick(() => {
      if (context.store._vm.$nuxt && context.store._vm.$nuxt.$loading && !noLoading) {
        context.store._vm.$nuxt.$loading.start();
      }
      return config;
    });
  });

  /**
   * Response時のイベントハンドラ
   */
  context.$axios.onResponse((response: AxiosResponse) => {
    // 非同期処理中のインジケーターを非表示にする
    context.store._vm.$nextTick(() => {
      if (context.store._vm.$nuxt && context.store._vm.$nuxt.$loading) {
        context.store._vm.$nuxt.$loading.finish();
      }
      return response;
    });
  });

  /**
   * エラー発生時のイベントハンドラ
   *
   * ・AWSのALB(WAF)から金融機関ごとにメンテナンスモードが設定できます。
   * （当該メンテナンス条件に該当する場合）
   * ``` レスポンスの内容例(AWS側で設定)
   * {
   *  "status": "maintenance", // 固定
   *  "message": "現在メンテナンス中です。", // 固定
   *  "data": {
   *    "scope": "chanto", // 固定
   *    "start_date": "2024年 12月 23日 10:00 頃", // 任意の日付フォーマット
   *    "end_date": "2025年 01月 06日 8:00 頃" // 任意の日付フォーマット
   *  }
   * }
   * ```
   * ・ Sentryにエラーを通知します。
   * ・ 共通エラー処理を実行するAPIで422が発生した時、noticeストアにバリデーションエラーを発行します。
   * ・ 共通エラー処理を実行するAPIで401,403,422以外のエラーが発生した時は、500エラーに変換してからエラー画面表示を呼び出します。
   */
  context.$axios.onError((e: AxiosError) => {
    /**
     * 「共通のエラー処理を実行するAPIか？」判定します。
     * パス、HTTPメソッド、クライアント側からのスキップフラグにより判定します。
     *
     * @param config
     * @returns
     */
    const shouldHandleCommonError = (config?: AxiosRequestConfig) => {
      // Sentryへエラー通知
      if (!shouldIgnoreEvent(e)) {
        context.$sentry?.captureException(e, {
          // 同じURLの場合、ステータスコードが違っても同じエラーと判定されるので`fingerprint`を変更して違うエラーと認識させている
          fingerprint: [e.response?.status, e.config?.url || ""]
        });
      }

      const path = config?.url || "";
      const method = config?.method || "";
      const matchedPath = Object.keys(errorHandlingRoutes).find((pattern) => {
        // 下記のようにパス文字列を変換します。
        // ex: path/{param}/action => ^path/[^/]+/action$ ※'{}'は変換の対象外
        // ※ `[^/]+`は`/`以外の1つ以上の文字という意味
        const regexPattern = "^" + pattern.replace(/{[^}]+}/g, "[^/]+") + "$";
        return new RegExp(regexPattern).test(path);
      });
      const isMatched = !!matchedPath && errorHandlingRoutes[matchedPath].includes(method);

      return !config?.skipCommonErrorHandling && isMatched;
    };

    // 非同期処理中のインジケーターを非表示にする
    context.store._vm.$nextTick(() => {
      if (context.store._vm.$nuxt && context.store._vm.$nuxt.$loading) {
        context.store._vm.$nuxt.$loading.finish();
      }
    });

    // メンテナンス中の場合は503エラーを返す
    if (
      e.response?.status === MAINTENANCE_RESPONSE.STATUS_CODE &&
      e.response?.data.status === MAINTENANCE_RESPONSE.STATUS &&
      e.response?.data.data.scope === MAINTENANCE_RESPONSE.SCOPE
    ) {
      context.app.router.push({
        name: "maintenance",
        params: {
          isActive: true,
          startDate: e.response?.data.data.start_date,
          endDate: e.response?.data.data.end_date
        }
      });
      return;
    }

    // Sentryにエラをー送信する（ステータスコードとURLの組み合わせで違うエラーとしている）
    context.$sentry && context.$sentry.captureException(e, { fingerprint: [e.response?.status, e.config?.url || ""] });

    if (!shouldHandleCommonError(e.config)) return;

    if (e.response?.status === 422) {
      context.store.dispatch("notice/show", { message: e.response?.data.message });
      return;
    }

    context.error({ statusCode: e.response?.status === 401 || e.response?.status === 403 ? e.response.status : 500 });
  });
}
