import { baPaymentStore, baStore } from "@/store";
import { isPaymentEndDateValid, isUseValid } from "~/utils";
import { getBaCompany } from "~/apis/user";
import Ba from "~/store/ba";
import {
  changePermissionsLink,
  chantoTermLink,
  cookiePolicyLink,
  kokopelliIdTermLink,
  registerConfirmLink,
  registerLink,
  supportFaqLink,
  supportQaLink,
  topLink
} from "~/utils/routes";
import { BA_USER_TYPE, USER_ROLE } from "~/utils/constants";
import { IBaPaymentInfo, IBaPaymentUser } from "~/types/ba-payment";
import { IBaUser } from "~/types/ba";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default async function ({ $auth, error, route, redirect, $sentry, store }) {
  /** ミドルウェアで使用する関数の定義 **/
  /**
   * エラー発生時、Sentryにイベント時の共通データをセットする
   * 仮にロギング処理内でエラーが発生しても、無視して通常処理を続行させるようにしています。
   */
  const setDimensionToSentry = (baStore: Ba) => {
    try {
      $sentry.setUser({
        ba_company_name: baStore.user.ba_company_name,
        ba_company_type: baStore.user.ba_company_type,
        ba_company_unique_code: baStore.user.ba_company_unique_code,
        ba_user_type: baStore.user.ba_user_type,
        ba_user_unique_code: baStore.user.ba_user_unique_code,
        user_kind: "general"
      });
    } catch {}
  };

  /**
   * ユーザーのロールが適切かどうかを判定する
   * @param userType
   */
  const isValidUserType = (userType: string) => {
    const validUserTypes = [
      BA_USER_TYPE.COMPANY_OWNER, // 企業オーナー
      BA_USER_TYPE.COMPANY_USER, // ビジネスユーザー
      BA_USER_TYPE.SYSTEM_ADMIN, // システム管理者
      BA_USER_TYPE.BANK_ADMIN, // 金融機関（事務局）
      BA_USER_TYPE.BRANCH_ADMIN, // 金融機関（支店）
      BA_USER_TYPE.BRANCH_USER // 金融機関（支店）ユーザー
    ];
    return validUserTypes.includes(userType);
  };

  /**
   * 契約中かどうかを確認する
   * @param paymentInfo
   */
  const isContract = (paymentInfo: IBaPaymentInfo) => {
    // TODO: plan_id = 0 を契約無しとして扱って良いか確認する
    if (paymentInfo.plan_id === 0) {
      // まだba-paymentを使っていない状態ならば無課金状態
      return false;
    } else if (paymentInfo.withdrawal_date) {
      // 退会日付が設定されていた場合は、課金終了日が今日以下であれば無課金状態とみなす。
      return isPaymentEndDateValid(paymentInfo.end_date);
    }
    return true;
  };

  /**
   * 有効なユーザーが存在するかどうかを確認する
   * NOTE:
   * baPaymentStore.user.roleは、自身が有効なユーザーでない場合にデフォルト値の0で設定される
   */
  const isExistValidUser = (user: IBaPaymentUser) => {
    return user.role !== 0;
  };

  /**
   * 権限移譲を考慮してオーナーかどうかを確認する
   * NOTE:
   * 権限移譲してもbaStore.user.ba_user_typeは変更されないため
   * あえてgetBaCompany()で取得したデータを使用してオーナーかどうかを判定する。
   */
  const isOwnerWithTransferAuthority = async (baUser: IBaUser) => {
    const result = await getBaCompany();
    const baOwner = result.data.result.owner;
    return baUser.ba_user_unique_code === baOwner.user_unique_code;
  };

  /**
   * ユーザーロールに変更があるかどうかを確認する
   * @param baUser
   * @param paymentUser
   */
  const isRoleChanged = (baUser: IBaUser, paymentUser: IBaPaymentUser) => {
    /**
     * BAのユーザータイプがちゃんと請求書に登録されたロールから変わったかどうかを確認する
     * @param baUser
     * @param paymentUser
     */
    const isRoleMatch = (baUser: IBaUser, paymentUser: IBaPaymentUser) => {
      // baのuser_typeをba-payment用に変換
      const baUserType =
        baUser.ba_user_type === BA_USER_TYPE.COMPANY_OWNER ? USER_ROLE.COMPANY_OWNER : USER_ROLE.COMPANY_USER;
      return baUserType === paymentUser.role;
    };

    // ちゃんと請求書のユーザーデータが存在する場合
    // BAとba-paymentでユーザーのロールに差異がある場合は権限変更ページへ
    return !isRoleMatch(baUser, paymentUser);
  };

  /**
   * ユーザーの有効期限確認処理の対象ページかどうかを判定する
   * @param path
   */
  const isPathToCheckUser = (path: string) => {
    // 除外対象ページ
    const excludedPages = [
      topLink,
      changePermissionsLink,
      registerLink,
      registerConfirmLink,
      chantoTermLink,
      kokopelliIdTermLink,
      supportFaqLink,
      supportQaLink,
      cookiePolicyLink
    ];
    // 除外対象ページ以外はチェック対象ページとする
    return !excludedPages.includes(path);
  };

  /**
   * ユーザーの入会日・退会日がセットされているかどうかを判定する
   * @param paymentUser
   */
  const isSetUseDate = (paymentUser: IBaPaymentUser) => {
    return paymentUser.use_start_date && paymentUser.use_end_date;
  };

  /** ミドルウェアの処理開始 **/
  // 1. ログインしていない場合はエラーにする
  if (!$auth.loggedIn) {
    error({ statusCode: 401 });
    return;
  }

  store.commit("notice/clearState");

  // 2. アクセストークンからBAユーザ情報を取得する
  await baStore.loadUser();

  // 3. エラー発生時にSentryへ常に送信する基本情報をセット
  setDimensionToSentry(baStore);

  // 4. クレジットカードが登録されていることを確認する
  if (!baStore.user.is_credit_card) {
    error({ statusCode: 401 });
    console.error("BA側でクレジットカードが未登録です");
    return;
  }

  // 5. ユーザーのロールが適切であることを確認する
  if (!isValidUserType(baStore.user.ba_user_type)) {
    error({ statusCode: 401 });
    console.error("ユーザーのロールが適切ではありません");
    return;
  }

  // 6. ちゃんと請求書のユーザー情報を取得する
  await baPaymentStore.loadUser();

  // 7. 権限変更ページの場合は以降のミドルウェアを抜ける
  if (route.path === changePermissionsLink) {
    return;
  }

  // 8. 契約中の場合はユーザーロールの確認を行う
  if (isContract(baPaymentStore.paymentInfo)) {
    if (!isExistValidUser(baPaymentStore.user)) {
      const isOwner = await isOwnerWithTransferAuthority(baStore.user);
      if (isOwner) {
        // 未登録のビジネスユーザーが企業オーナーへ権限変更された場合
        redirect(changePermissionsLink);
        return;
      }
      // 企業オーナーではないケース（通常起こり得ない）
      error({ statusCode: 401 });
      console.error("未登録のユーザーでユーザーのロールが適切ではありません");
      return;
    }

    // 登録済みユーザーが企業オーナーへ権限変更された場合
    if (isRoleChanged(baStore.user, baPaymentStore.user)) {
      // 権限に変更があれば権限変更ページへリダイレクトさせる
      redirect(changePermissionsLink);
      return;
    }
  }

  // 9. 確認が必要なページにおいて、ユーザーの入会日・退会日が適切かどうかを確認する
  if (isPathToCheckUser(route.path)) {
    if (!isSetUseDate(baPaymentStore.user)) {
      // 入会日・退会日がセットされていない場合はエラーにする
      error({ statusCode: 401 });
      console.error("未登録のユーザーです");
      return;
    }
    if (!isUseValid(baPaymentStore.user.use_start_date, baPaymentStore.user.use_end_date)) {
      // 利用期限外であればエラーにする
      error({ statusCode: 401, message: "利用期限が切れています" });
      console.error("利用期限が切れています");
      return; // eslint-disable-line
    }
  }
}
