import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

import { IBaPaymentUser, IBaPaymentInfo, PlanInfo } from "@/types/ba-payment";
import { getBaPaymentMe, getBaPaymentInfo, getBaPlanInfo } from "@/apis/user";

export interface State {
  user: IBaPaymentUser;
  paymentInfo: IBaPaymentInfo;
}

/**
 *
 *
 * @export
 * @class BaPayment
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: "ba-payment" })
export default class BaPayment extends VuexModule implements State {
  user: IBaPaymentUser = {
    user_unique_code: "",
    company_unique_code: "",
    role: 0,
    registration_status: 0,
    service_id: 0,
    plan_id: 0,
    use_start_date: "",
    use_end_date: "",
    user_id: 0,
    reference_id: 2,
    receive_reference_id: 1
  };

  paymentInfo: IBaPaymentInfo = {
    plan_id: 0,
    join_date: "",
    start_date: "",
    end_date: "",
    withdrawal_date: null
  };

  planInfo: PlanInfo = {
    plan_id: 0,
    previous_plan_id: 0,
    plan_name: "",
    bank_code: "",
    join_date: "",
    start_date: "",
    end_date: "",
    withdrawal_date: "",
    is_contract: false,
    is_plan_changed: false,
    current_amount: 0,
    current_total_amount: 0,
    amount: 0,
    total_amount: 0,
    issue_amount: 0,
    issue_total_amount: 0,
    receive_amount: 0,
    receive_total_amount: 0,
    max_receive_files: 0,
    is_free_term: false,
    is_subscribe_cancellation: false,
    is_campaign: false,
    campaign_num_of_months: 0,
    campaign_start_date: "",
    campaign_finish_date: "",
    is_campaign_applied: false,
    applied_campaign_num_of_months: 0,
    applied_campaign_start_date: "",
    applied_campaign_finish_date: "",
    applied_campaign_end_date: ""
  };

  isLoadUser = false;

  @Mutation
  SET_USER_VALUE(item: IBaPaymentUser): void {
    this.user = item;
  }

  @Mutation
  SET_PAYMENT_INFO(item: IBaPaymentInfo): void {
    this.paymentInfo.plan_id = item.plan_id;
    this.paymentInfo.join_date = item.join_date;
    this.paymentInfo.start_date = item.start_date;
    this.paymentInfo.end_date = item.end_date;
    this.paymentInfo.withdrawal_date = item.withdrawal_date;
  }

  @Mutation
  SET_PLAN_INFO(planInfo: PlanInfo) {
    this.planInfo = planInfo;
  }

  /**
   * APIで取得したされたユーザーデータを保存する
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  loadUser(): Promise<boolean> {
    const promiseUser = getBaPaymentMe();
    const promisePayment = getBaPaymentInfo();
    const promisePlanInfo = getBaPlanInfo();

    return Promise.all([promiseUser, promisePayment, promisePlanInfo]).then(([user, paymentInfo, planInfo]) => {
      if (user.data && Object.keys(user.data.data).length) {
        this.SET_USER_VALUE(user.data.data);
      } else {
        this.SET_USER_VALUE({
          user_unique_code: "",
          company_unique_code: "",
          role: 0,
          registration_status: 0,
          service_id: 0,
          plan_id: 0,
          use_start_date: "",
          use_end_date: "",
          user_id: 0,
          reference_id: 2,
          receive_reference_id: 1
        });
      }

      if (paymentInfo.data && paymentInfo.data.data.length) {
        this.SET_PAYMENT_INFO(paymentInfo.data.data[0]);
      } else {
        this.SET_PAYMENT_INFO({
          plan_id: 0,
          join_date: "",
          start_date: "",
          end_date: "",
          withdrawal_date: null
        });
      }

      this.SET_PLAN_INFO(planInfo?.data?.data || this.planInfo);

      return true;
    });
  }

  /**
   * 「プラン情報取得API」を呼び出しプラン情報を更新します
   */
  @Action({ rawError: true })
  loadPlan() {
    getBaPlanInfo().then((data) => {
      this.SET_PLAN_INFO(data?.data?.data || this.planInfo);
      return true;
    });
  }

  /**
   * APIで取得したされたユーザーデータを保存する
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  setUser(result: IBaPaymentUser): Promise<boolean> {
    return Promise.all([result]).then(([user]) => {
      this.SET_USER_VALUE(user);
      return true;
    });
  }
}
