
import { ref, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const loading = ref<boolean>(false);

    const start = (): void => {
      loading.value = true;
    };
    const finish = (): void => {
      loading.value = false;
    };

    return {
      loading,
      start,
      finish
    };
  }
});
