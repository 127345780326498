
import { defineComponent, PropType, useRoute } from "@nuxtjs/composition-api";

import { MenuItem } from "./SideNavi.vue";

/**
 * サイドナビメニューアイテム
 */
export default defineComponent({
  name: "NaviMenu",
  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true
    },
    userRole: {
      type: Number,
      required: true
    }
  },
  setup() {
    const route = useRoute();

    const isActive = (path: string): boolean => {
      const currentPath = route.value.path;

      if (currentPath.indexOf(path) === 0) {
        return true;
      }

      return false;
    };

    return {
      isActive
    };
  }
});
