import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

import { IBaUser, Bank } from "@/types/ba";
import { decodeJwt } from "@/utils";
import { getAccessToken, getBaConfig, getUserInfo } from "@/apis/user";

export interface State {
  user: IBaUser;
  bank: Bank;
}

/**
 *
 *
 * @export
 * @class Ba
 * @extends {VuexModule}
 * @implements {State}
 */
@Module({ stateFactory: true, namespaced: true, name: "ba" })
export default class Ba extends VuexModule implements State {
  user: IBaUser = {
    ba_company_name: "",
    ba_company_type: "",
    ba_company_unique_code: "",
    ba_user_bank_code: "",
    ba_user_bank_name: "",
    ba_user_bb_code: "",
    ba_user_bb_name: "",
    ba_user_email: "",
    ba_user_first_name: "",
    ba_user_first_name_kana: "",
    ba_user_last_name: "",
    ba_user_last_name_kana: "",
    ba_user_name: "",
    ba_user_type: "",
    ba_user_unique_code: "",
    ba_user_bb_unique_code: "",
    is_credit_card: false
  };

  bank: Bank = {
    ba_name: "",
    // NOTE: 発行型については、BAMのON/OFFに応じた表示の出し分けの影響範囲が大きいため、対応方針を検討後実装予定
    // is_enable_payment_issue: true,
    is_enable_payment_receive: true
  };

  @Mutation
  SET_USER_VALUE(item: IBaUser): void {
    if (!sessionStorage.getItem("payload")) {
      sessionStorage.setItem("payload", JSON.stringify(item));
    }

    this.user = item;
  }

  @Mutation
  SET_BANK_VALUE(item: Bank): void {
    this.bank = item;
  }

  /**
   * ユーザーデータを保存する
   *
   * @return {boolean}  {Promise<boolean>}
   * @memberof User
   */
  @Action({ rawError: true })
  async loadUser(): Promise<boolean> {
    const decodeUserInfo = decodeJwt();
    if (!decodeUserInfo) {
      return false;
    }

    // ローカルストレージにアクセストークンが存在しなかったら取得する
    if (!localStorage.getItem("auth.access-token")) {
      const accessTokenResult = await getAccessToken();
      localStorage.setItem("auth.access-token", accessTokenResult.data.result.access_token);
    }

    // userinfo取得
    const userInfoResult: any = await getUserInfo(
      decodeUserInfo?.ba_user_bank_code,
      localStorage.getItem("auth.access-token") as string
    );

    const config: any = await getBaConfig();
    const isCreditCard = !(
      config.data.result.config.check_payment_method_filled && !config.data.result.config.is_payment_method_filled
    );

    const baUserData = {
      ba_company_name: userInfoResult.data.ba_company_name ?? "",
      ba_company_type: userInfoResult.data.ba_company_type ?? "",
      ba_company_unique_code: userInfoResult.data.ba_company_unique_code ?? "",
      ba_user_bank_code: userInfoResult.data.ba_user_bank_code ?? "",
      ba_user_bank_name: userInfoResult.data.ba_user_bank_name ?? "",
      ba_user_bb_code: userInfoResult.data.ba_user_bb_code ?? "",
      ba_user_bb_name: userInfoResult.data.ba_user_bb_name ?? "",
      ba_user_email: userInfoResult.data.ba_user_email ?? "",
      ba_user_first_name: userInfoResult.data.ba_user_first_name ?? "",
      ba_user_first_name_kana: userInfoResult.data.ba_user_first_name_kana ?? "",
      ba_user_last_name: userInfoResult.data.ba_user_last_name ?? "",
      ba_user_last_name_kana: userInfoResult.data.ba_user_last_name_kana ?? "",
      ba_user_name: userInfoResult.data.ba_user_name ?? "",
      ba_user_type: userInfoResult.data.ba_user_type ?? "",
      ba_user_unique_code: userInfoResult.data.ba_user_unique_code ?? "",
      ba_user_bb_unique_code: userInfoResult.data.ba_user_bb_unique_code ?? "",
      is_credit_card: isCreditCard
    };

    const bankData = {
      ba_name: config.data.result.config.bank.ba_name ?? "",
      is_enable_payment_receive: config.data.result.config.enable_payment_receive ?? false // BAMからフラグが渡ってこない場合はfalseとする
    };

    return Promise.all([baUserData, bankData]).then(([user, bank]) => {
      if (user) this.SET_USER_VALUE(user);
      if (bank) this.SET_BANK_VALUE(bank);
      return true;
    });
  }
}
