
import { defineComponent } from '@nuxtjs/composition-api';

/**
 * サイドナビ ユーザーカード
 */
export default defineComponent({
  name: 'UserCard',
  props: {
    bankName: {
      type: String,
      required: true
    },
    babbName: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  }
});
